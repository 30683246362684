import React from "react"
import { useState, useEffect } from "react"

import Layout from "../components/layout"
import TextBlock from "../components/text-block"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"

import styled from "styled-components"
import QRCode from "qrcode"

const TextBlockCentered = styled(TextBlock)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: "center";
  align-items: center;

  img {
    max-width: 90vw;
    height: auto;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  }
`

const MeetingPage = ({ location }) => {
  const [qrUrl, setQrUrl] = useState(null)

  useEffect(() => {
    let isMounted = true
    fetch("/patrickkrueger.vcf")
      .then((response) => response.text())
      .then((data) => {
        QRCode.toDataURL(data, {
          errorcorrectionLevel: "L",
        })
          .then((url) => {
            if (isMounted) {
              setQrUrl(url)
            }
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <Layout location={location}>
      <Seo title="vCard" />
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <TextBlockCentered bg="green">
        <h1>vCard</h1>
        <div
          style={{
            maxWidth: "300px",
            width: "100%",
            aspectRatio: "1 / 1",
          }}
        >
          {qrUrl && (
            <a
              href="/patrickkrueger.vcf"
              style={{ textDecoration: "none", borderBottom: "none" }}
            >
              <img
                src={qrUrl}
                alt="QR Code"
                style={{ width: "100%", height: "auto" }}
              />
            </a>
          )}
        </div>
        <p>
          <a href="/patrickkrueger.vcf">Download</a>
        </p>
      </TextBlockCentered>
    </Layout>
  )
}
export default MeetingPage
